@import '../../scss/_variables.scss';
@import '../../scss/components/_button.scss';

.header {
  border-bottom: 1px solid $gray-line;
  padding: 40px 0;
  cursor: auto;

  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    margin: 0 auto;
    width: 90%;
    @media (max-width: 942px) {
      & {
        justify-content: space-between;
      }
    }
  }
  &__logo {
    display: flex;

    img {
      margin-right: 15px;
    }

    h1 {
      color: #181818;
      font-size: 24px;
      letter-spacing: 1%;
      text-transform: uppercase;
      font-weight: 800;
    }

    p {
      white-space: nowrap;
      color: #7b7b7b;
    }
  }
}
