@import '../../scss/_variables.scss';


.categories {
  overflow: auto;
  :global(.active){
    background: black ;
    color: white;
    &:hover{
      background: black ;
    }
  }
  ul {
    display: flex;
    white-space: nowrap;
    overflow: auto;
    li {
      background-color: #f9f9f9;
      padding: 13px 30px;
      border-radius: 30px;
      margin-right: 10px;
      font-weight: bold;
      cursor: pointer;
      transition: background-color 0.1s ease-in-out;

      &:hover {
        background-color: darken(#f9f9f9, 2%);
      }


      &.active {
        background-color: #282828;
        color: #fff;
      }
    }
  }
}
