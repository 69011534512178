.root{
  text-align: center;
  span{
   font-size: 70px;
  }
  padding: 200px;
}

.description{
  font-size: 22px;
}