.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 300px;
  max-width: 40%;
  margin: 0px 50px;
  position: relative;

  @media (max-width: 950px) {
    & {
      margin-top: 30px;
      order: 3;
      min-width: 250px;
    }
  }
  .input {
    transition: 0.1s ease-in-out;
    box-sizing: border-box;
    border: 0;
    outline: 1px solid rgba(0, 0, 0, 0.356);
    border-radius: 13px;
    padding: 20px 10px;
    padding-left: 20px;
    font-size: 18px;
    min-width: 300px;
    max-width: 40%;
    &:focus {
      box-sizing: border-box;
      transition: 0.1s ease-in-out;
      outline: 1px solid rgba(0, 0, 0, 0.623);
      box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.062);
    }
  }
}
.input__cross {
  width: 20px;
  cursor: pointer;
  position: absolute;
  right: 20px;
}
